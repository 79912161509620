.containerThankYou {
    padding-top: 10%;
    padding-bottom: 10%;
}

.background {
    top: 0px;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
}

.recipinsLink {
    background-color: white;
    width: 140px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.centerDesktop {
    padding-top: 10%;
    text-align: center;
}

.centerMobile {
    padding-top: 15%;
    text-align: center;
}

.welcome {
    margin-bottom: 0px;
    color: black;
    font-size: 32px;
}

.link {
    color: white;
    font-size: 24px;
    text-decoration: none;
    padding-left: 5px;
    padding-right: 5px;
}

.containerLinks {
    padding-top: 2%;
    color: white;
    font-size: 24px;
    text-decoration: none;
}

.black {
    color: black;
}
  
.blackNoMargin {
    margin: 0;
    padding: 0;
}

.recipinsLogo {
    padding-top: 7px;
    height: 50px;
}

.title {
    padding-top: 5px;
    margin-bottom: 0px;
    text-align: center;
    font-style: normal;
    font-size: 24px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}
