.bar {
    top: 0px;
    width: 100%;
    height: 61px;
    z-index: 999;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
  
.menu {
    display: flex;
    justify-content: end;
    position: absolute;
    right: 10px;
}
  
.options {
    margin-right: 12px;
}
  
.title {
    position: absolute;
    left: 24px;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 24px;
    text-decoration: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}

.logoDesktop {
    font-size: 24px;
    text-decoration: none;
    padding-top: 7px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}

.logoMobile {
    font-size: 24px;
    text-decoration: none;
    padding-top: 7px;
    height: 50px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}
