.centerDesktop {
    margin-left: auto;
    margin-right: auto;
    width: 550px;
}

.centerMobile {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}


