.element {
    padding-bottom: 8px;
}

.options { 
    position: absolute;
    top: 20px;
    width: 160px;
    z-index: 991;
    margin-top: 35px;
    transition: height 0.3s ease-in-out 0s;
    right: 0px;
    margin-right: 4px;
    max-height: calc(-59px + 100vh);
    color: black;
    box-shadow: 0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%);
    height: 100px;
    border-radius: 20px;
}

.optionsMenu {
    position: absolute;
    top: 10px;
    left: 20px;
}

.name {
    padding-left: 10px;
}
