/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
        font-size: 16px;
    }
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

html {
  font-size: 16px;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.container {
  width: 100%;
  max-width: 100%;
  padding-top: 0px;
  --bs-gutter-x: 0;
  background-color: white;
}

.shadow-input {
  width: 100%;
  float: right;
  height: 25px;
  margin-top: 5px;
  text-align: left;
  text-indent: 5px;
  font-size: 10pt;
  border-radius: 5px;
  border: solid 1.5px #D3D3D3;
  -webkit-transition: 1s; /* Safari */
  transition: 1s;
}

.shadow-input:hover{
  box-shadow: 0 0 5pt 0.5pt #D3D3D3;
}

.shadow-input:focus {
  box-shadow: 0 0 5pt 2pt #D3D3D3;
  outline-width: 0px;
}

.black{
  color: black;
}

.no-margin {
  margin: 0;
  padding: 0;
}

.left {
  left: 0px;
}

.right {
  right: 0px;
}
