.centerDesktop {
    margin-left: auto;
    margin-right: auto;
    width: 350px;
}

.centerMobile {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}


