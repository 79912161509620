.centerDesktop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centerMobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


