.title {
    padding-top: 5px;
    margin-bottom: 0px;
    text-align: center;
    font-style: normal;
    font-size: 24px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Noto Sans",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
}

.containerDesktop {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.containerMobile {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

